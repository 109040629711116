import Jumbotron from './js/jumbotron';
import Page from "./js/page";
import Navbar from "./js/navbar"
import ImageGallery from "./js/image-gallery"
document.addEventListener('DOMContentLoaded', function() {
	Jumbotron.init();
	Page.init();
	Navbar.init();
	ImageGallery.init();
});
