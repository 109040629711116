const Jumbotron = {
	leftButton: null,
	rightButton: null,
	scrollElement: null,

	rotateInterval: null,
	scrollTimeout: null,

	init: function() {
		if (!document.getElementById('jumbotron')) {
			return;
		}
		this.leftButton = document.getElementById('jumbotron-control-left');
		this.leftButton.addEventListener('click', this.leftButtonClick.bind(this));
		this.rightButton = document.getElementById('jumbotron-control-right');
		this.rightButton.addEventListener('click', this.rightButtonClick.bind(this));

		this.scrollElement = document.getElementById('jumbotron-content-inner');
		this.scrollElement.addEventListener('scroll', this.checkScrollingEnd.bind(this));

		this.rotateInterval = setInterval(this.rightButtonClick.bind(this), 5000);
	},

	leftButtonClick: function() {
		const item = this.scrollElement.querySelector('.jumbotron-item');
		const itemWidth = item.offsetWidth;
		this.scrollElement.scrollLeft -= itemWidth;
	},

	rightButtonClick: function() {
		const item = this.scrollElement.querySelector('.jumbotron-item');
		const itemWidth = item.offsetWidth;
		this.scrollElement.scrollLeft += itemWidth;

	},

	checkScrollingEnd: function() {
		const item = this.scrollElement.querySelector('.jumbotron-item');
		const itemWidth = item.offsetWidth;
		const scrollLeft = this.scrollElement.scrollLeft;
		const maxScrollLeft = this.scrollElement.scrollWidth - this.scrollElement.offsetWidth - 30;
		if (scrollLeft >= maxScrollLeft) {
			this.scrollElement.style.scrollBehavior = 'unset';
			this.scrollElement.scrollLeft = 0;
			this.scrollElement.style.scrollBehavior = 'smooth';
		}
	}

};

export default Jumbotron;
