const Page = {
	menuButtonStart: null,
	menuButtonEnd: null,
	pageContainer: null,
	menuContainer: null,

	init: function() {
		if (!document.getElementById('page-menu-container')) {
			return;
		}
		this.pageContainer = document.getElementById('page-container');
		this.pageMenuContainer = document.getElementById('page-menu-container');

		// this.menuButtonStart = document.getElementById('page-menu-button-start');
		// this.menuButtonStart.addEventListener('click', this.toggleMenu.bind(this));

		this.menuButtonEnd = document.getElementById('page-menu-button-end');
		this.menuButtonEnd.addEventListener('click', this.toggleMenu.bind(this));
	},

	toggleMenu: function() {
		if (this.pageContainer.classList.contains('menu-open')) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	},

	clickOutsideMenu: function(event) {
		if (!this.pageMenuContainer.contains(event.target)) {
			this.closeMenu();
		}
	},

	openMenu: function() {
		this.pageContainer.classList.add('menu-open');
		window.addEventListener('click', this.clickOutsideMenu.bind(this));
	},

	closeMenu: function() {
		this.pageContainer.classList.remove('menu-open');
		window.removeEventListener('click', this.clickOutsideMenu.bind(this));
	}

};

export default Page;
