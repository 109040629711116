const ImageGallery = {

	mainImage: null,
	thumbnailImages: null,

	init: function() {
		this.mainImage = document.getElementById('main-image');
		this.thumbnailImages = document.querySelectorAll('.gallery-list-image');
		this.thumbnailImages.forEach((image) => {
			image.addEventListener('click', this.thumbnailClick.bind(this));
		});
	},

	thumbnailClick: function(event) {
		const image = event.target;
		const src = image.getAttribute('src');
		this.mainImage.setAttribute('src', src);
	}
}

export default ImageGallery;
