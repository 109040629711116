const Navbar = {

	navbar: null,
	navbarToggler: null,
	navbarNav: null,

	init: function () {
		this.navbarToggler = document.getElementById('navbar-toggler');
		this.navbarToggler.addEventListener('click', this.toggleMenu.bind(this));

		this.navbarNav = document.getElementById('navbar-nav');
		this.navbar = document.getElementById('navbar');
	},

	toggleMenu: function() {
		if (this.navbar.classList.contains('menu-open')) {
			this.closeMenu();
		} else {
			this.openMenu();
		}
	},

	clickOutsideMenu: function(event) {
		if (!this.navbar.contains(event.target)) {
			this.closeMenu();
		}
	},

	openMenu: function() {
		this.navbar.classList.add('menu-open');
		window.addEventListener('click', this.clickOutsideMenu.bind(this));
	},

	closeMenu: function() {
		this.navbar.classList.remove('menu-open');
		window.removeEventListener('click', this.clickOutsideMenu.bind(this));
	}
}

export default Navbar;
